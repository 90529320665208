<template>
  <main>
    <CForm>
      <CCard>
        <CCardHeader class="d-flex align-items-center justify-content-between">
          <h5 class="mb-0">{{ $t('Navigation.System/Logistics') }}</h5>
          <div class="card-header-actions d-flex">
            <CButton color="success" type="button" @click="OpenModal()">
              {{ $t('Global.Add') + $t('Logistics.Method') }}
            </CButton>
          </div>
        </CCardHeader>
        <CCardBody class="p-0">
          <CDataTable
            id="LogisticsList"
            :items="List"
            :fields="Field"
            :loading="Loading"
            :noItemsView="noItemsView"
            responsive
            hover
          >
            <template #No="{index}">
              <td class="text-center">
                {{ index + 1}}
              </td>
            </template>
            <template #Status="{item}">
              <td>
                <CBadge :color="(item.Status === true ? 'success' : 'danger')">
                  {{ $t('Logistics.StatusType.' + item.Status) }}
                </CBadge>
              </td>
            </template>
            <template #System="{item}">
              <td>
                {{ $t('Logistics.LogisticsSystem.' + item.System) }}
              </td>
            </template>
            <template #Method="{item}">
              <td>
                {{ $t('Logistics.LogisticsMethods.' + item.Method) }}
              </td>
            </template>
            <template #Action="{item}">
              <td>
                <CButton color="info" size="sm" class="mr-1" v-c-tooltip="$t('Global.Edit')" @click="OpenModal(item.ID)">
                  <CIcon name="cil-pencil" class="c-icon-custom-size" />
                </CButton>
                <CButton color="danger" size="sm" class="mr-1" v-c-tooltip="$t('Global.Remove')" @click="Delete(item.ID)">
                  <CIcon name="cil-trash" class="c-icon-custom-size"/>
                </CButton>
                <CButton color="secondary" size="sm" class="mr-1" v-c-tooltip="$t('Global.Duplicate')" @click="Duplicate(item.ID)">
                  <CIcon name="cil-copy" class="c-icon-custom-size mr-1" />
                </CButton>
              </td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CForm>
    <CModal id="LogisticsDataModal" class="ActionModel" size="lg" :show.sync="LogisticsDataModal" :closeOnBackdrop="!Submit">
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Global.' + (!ChooseIndex ? 'Add' : 'Edit')) }}{{ $t('Logistics.Method') }}</h5>
          <CButtonClose @click="LogisticsDataModal = false"/>
        </header>
      </template>
      <CTabs fade addTabsWrapperClasses="tab-info">
        <CTab :title="$t('Logistics.BasicSetting')" active>
          <CInput horizontal v-model="LogisticsData.Name" :label="$t('Logistics.Name')" :placeholder="(LogisticsData.Method !== '' ? $t('Logistics.Example') + ': ' + $t('Logistics.LogisticsMethods.' + LogisticsData.Method) : $t('Logistics.NamePlaceholder'))"/>
          <CSelect horizontal :label="$t('Logistics.System')" :options="LogisticsSystem" v-model="LogisticsData.System" :value.sync="LogisticsData.System" :placeholder="$t('Global.PleaseSelect')"/>
          <CSelect horizontal :label="$t('Logistics.Method')" :options="ShowLogisticsMethods" v-model="LogisticsData.Method" :value.sync="LogisticsData.Method" :placeholder="$t('Global.PleaseSelect')" @change="CheckExcludeAreaEnable()" />
          <CSelect horizontal :label="$t('Logistics.ServiceLocation')" :options="ServiceLocationOptions" v-model="LogisticsData.ServiceLocation" :value.sync="LogisticsData.ServiceLocation" :placeholder="$t('Global.PleaseSelect')" @change="CheckPropertyInfoEnable()" />
          <CSelect v-if="(LogisticsData.ServiceLocation === 'ABROAD')" horizontal :label="$t('Logistics.ShipmentCountry')" :options="CountryOptions" v-model="LogisticsData.ShipmentCountry" :value.sync="LogisticsData.ShipmentCountry" :placeholder="$t('Global.PleaseSelect')" @change="CheckPropertyInfoEnable()" />
          <CSelect horizontal :label="$t('Logistics.Temperature')" :options="TemperatureOptions" v-model="LogisticsData.Temperature" :value.sync="LogisticsData.Temperature" :placeholder="$t('Global.PleaseSelect')"/>
          <CRow form class="form-group">
            <CCol tag="label" sm="3" class="col-form-label">
              {{ $t('Logistics.Status') }}
              <i class="fas fa-info-circle text-info ml-1" v-c-tooltip="{content: '切換啟用狀態不影響銷售頁的配送方式的啟用與否，若銷售頁要停用這項配送方式，請至銷售頁將此配送方式移除。', placement: 'right'}" />
            </CCol>
            <CCol sm="9">
              <CSwitch color="success" :checked.sync="LogisticsData.Status"/>
            </CCol>
          </CRow>
          <CRow form class="form-group">
            <CCol tag="label" sm="3" class="col-form-label">
              {{ $t('Logistics.EnableCashDelivery') }}
            </CCol>
            <CCol sm="9">
              <CSwitch color="success" :checked.sync="LogisticsData.EnableCashDelivery"/>
            </CCol>
          </CRow>
          <CRow form class="form-group">
            <CCol tag="label" sm="3" class="col-form-label">
              {{ $t('Logistics.SettingFreeFee') }}
            </CCol>
            <CCol sm="9">
              <CSwitch color="success" :checked.sync="LogisticsData.IsFreeFee"/>
            </CCol>
          </CRow>
          <CInput v-if="!LogisticsData.PropertyInfoEnable" horizontal v-model="LogisticsData.Fee" :prepend="$store.state.currency + '$'" :label="$t('Logistics.Fee')" type="number" />
          <CInput v-if="LogisticsData.IsFreeFee === true" horizontal v-model="LogisticsData.FreeFee" :prepend="$store.state.currency + '$'" :label="$t('Logistics.FreeFee')" type="number" />
          <CInput horizontal v-model="LogisticsData.MinimumAmountThreshold" :prepend="$store.state.currency + '$'" :label="$t('Logistics.MinimumAmountThreshold')" :description="$t('Logistics.MinimumAmountThresholdDescription')" type="number" />
          <CInput horizontal v-model="LogisticsData.MaximumAmountThreshold" :prepend="$store.state.currency + '$'" :label="$t('Logistics.MaximumAmountThreshold')" :description="$t('Logistics.MaximumAmountThresholdDescription')" type="number" />
          <CRow form class="form-group">
            <CCol tag="label" sm="3" class="col-form-label">
              {{ $t('Logistics.Description') }}
            </CCol>
            <CCol sm="9">
              <Editor api-key="no-api-key" tinymceScriptSrc="https://cdnjs.cloudflare.com/ajax/libs/tinymce/5.10.9/tinymce.min.js" :init="TinyMCE" v-model="LogisticsData.Description" />
            </CCol>
          </CRow>
        </CTab>
        <CTab v-show="LogisticsData.Method !== 'SELF'" :title="$t('Logistics.ShipperInfo')">
          <CInput horizontal v-model="LogisticsData.ShipperInfo.Name" :label="$t('Logistics.ShipperName')" :placeholder="$t('Logistics.ShipperNameDescription')"/>
          <CInput horizontal v-model="LogisticsData.ShipperInfo.Company" :label="$t('Logistics.ShipperCompany')" :placeholder="$t('Logistics.ShipperCompanyDescription')"/>
          <CInput horizontal v-model="LogisticsData.ShipperInfo.City" :label="$t('Logistics.ShipperCity')" :placeholder="$t('Logistics.ShipperCityDescription')"/>
          <CInput horizontal v-model="LogisticsData.ShipperInfo.Area" :label="$t('Logistics.ShipperArea')" :placeholder="$t('Logistics.ShipperAreaDescription')"/>
          <CInput horizontal v-model="LogisticsData.ShipperInfo.ZipCode" :label="$t('Logistics.ShipperZipCode')" :placeholder="$t('Logistics.ShipperZipCodeDescription')"/>
          <CInput horizontal v-model="LogisticsData.ShipperInfo.Address" :label="$t('Logistics.ShipperAddress')" :placeholder="$t('Logistics.ShipperAddressDescription')"/>
          <CSelect horizontal :label="$t('Logistics.ShipperCountry')" :options="CountryOptions" v-model="LogisticsData.ShipperInfo.Country" :value.sync="LogisticsData.ShipperInfo.Country" :placeholder="$t('Global.PleaseSelect')"/>
          <CInput horizontal v-model="LogisticsData.ShipperInfo.Phone" :label="$t('Logistics.ShipperPhone')" :placeholder="$t('Logistics.ShipperPhoneDescription')"/>
          <CInput horizontal v-model="LogisticsData.ShipperInfo.TaxID" :label="$t('Logistics.ShipperTaxID')" :placeholder="$t('Logistics.ShipperTaxIDDescription')"/>
          <CInput horizontal v-model="LogisticsData.ShipperInfo.DefaultProductName" :label="$t('Logistics.DefaultProductName')" :placeholder="$t('Logistics.DefaultProductNameDescription')"/>
        </CTab>
        <CTab v-show="LogisticsData.Method === 'SELF' || LogisticsData.Method === 'IN_USE' || LogisticsData.Method === 'DELIVERY'" :title="$t('Logistics.StoreInfo')">
          <CInput horizontal v-model="LogisticsData.StoreInfo.Name" :label="$t('Logistics.StoreInfoName')" />
          <CInput horizontal v-model="LogisticsData.StoreInfo.City" :label="$t('Logistics.StoreInfoCity')" />
          <CInput horizontal v-model="LogisticsData.StoreInfo.Area" :label="$t('Logistics.StoreInfoArea')" />
          <CInput horizontal v-model="LogisticsData.StoreInfo.Address" :label="$t('Logistics.StoreInfoAddress')" :description="$t('Logistics.StoreInfoAddressDescription')"/>
          <CInput horizontal v-model="LogisticsData.StoreInfo.Phone" :label="$t('Logistics.StoreInfoPhone')" />
        </CTab>
        <CTab v-show="LogisticsData.Method !== 'SELF'" :title="$t('Logistics.ShipmentArea')">
          <div class="form-group">
            <label class="d-block">{{ $t('Logistics.CheckExcludeAreaEnable') }}<i v-c-tooltip="{content: $t('Logistics.CheckExcludeAreaEnableDescription'),placement: 'right'}" class="fas fa-question-circle text-info ml-1" /></label>
            <CSwitch color="success" :checked.sync="LogisticsData.CheckExcludeAreaEnable" :disabled="(CurrentMethod.type === 'SUPERSTORE' || LogisticsData.ServiceLocation === 'ABROAD')" @change="ChangeExcludeAreaEnable()" />
          </div>
          <div v-if="LogisticsData.CheckExcludeAreaEnable === true" class="form-group mb-0">
            <h6 class="d-flex justify-content-between mb-2">
              <span>{{ $t('Logistics.SettingExcludeArea') }}</span>
              <CButton color="success" size="sm" variant="ghost" v-c-tooltip="$t('Global.Add')"
                       @click="AddExcludeArea()">
                <CIcon name="cil-plus" class="c-icon-custom-size"/> {{ $t('Global.Add') }}
              </CButton>
            </h6>
            <CRow>
              <CCol col="5">{{ $t('System/Basic.LocationField.City') }}</CCol>
              <CCol col="5">{{ $t('System/Basic.LocationField.Area') }}</CCol>
            </CRow>
            <hr class="my-2">
            <CRow v-for="(AreaData, Index) in LogisticsData.ExcludeArea" :key="Index">
              <CCol col="5">
                <CSelect horizontal size="sm" :options="City" v-model="AreaData.City" :value.sync="AreaData.City" :placeholder="$t('Global.PleaseSelect')"/>
              </CCol>
              <CCol col="5">
                <CSelect horizontal size="sm" :options="DataArea(Index)" v-model="AreaData.Area" :value.sync="AreaData.Area" :placeholder="$t('Global.PleaseSelect')"/>
              </CCol>
              <CCol col="2" class="text-right">
                <CButton color="danger" size="sm" variant="ghost" v-c-tooltip="$t('Global.Remove')" @click="LogisticsData.ExcludeArea.splice(Index, 1)">
                  <CIcon name="cil-trash" class="c-icon-custom-size"/>
                </CButton>
              </CCol>
            </CRow>
          </div>
        </CTab>
        <CTab v-show="Features.includes('PropertyInfo') && LogisticsData.Method !== 'SELF'" :title="$t('Logistics.PropertyInfo')">
          <CAlert color="info" :show="(LogisticsData.ServiceLocation !== 'ABROAD')">
            <CIcon name="cil-bell"/>
            {{ $t('Logistics.PropertyInfoDisableDescription') }}
          </CAlert>
          <div class="form-group">
            <label class="d-block">{{ $t('Logistics.PropertyInfoEnable') }}<i v-c-tooltip="{content: $t('Logistics.PropertyInfoDescription'),placement: 'right'}" class="fas fa-question-circle text-info ml-1" /></label>
            <CSwitch color="success" :checked.sync="LogisticsData.PropertyInfoEnable" :disabled="(LogisticsData.ServiceLocation !== 'ABROAD')" />
          </div>
          <template v-if="(LogisticsData.PropertyInfoEnable === true)">
            <CSelect :label="$t('Logistics.RelationProperty')" :options="RelationPropertyOption" v-model="LogisticsData.RelationProperty" :value.sync="LogisticsData.RelationProperty" :placeholder="$t('Global.PleaseSelect')" class="mb-4" />
            <div class="form-group mb-0">
              <h6 class="d-flex justify-content-between mb-2">
                <span> {{ $t('Logistics.PropertyFeeSetting') }}</span>
                <CButton color="success" size="sm" variant="ghost" v-c-tooltip="$t('Global.Add')"
                         @click="LogisticsData.PropertyInfo.push({})">
                  <CIcon name="cil-plus" class="c-icon-custom-size"/> {{ $t('Global.Add') }}
                </CButton>
              </h6>
              <CRow>
                <CCol col="5">{{ $t('Logistics.PackageWeight') }}</CCol>
                <CCol col="5">{{ $t('Logistics.Fee') }}</CCol>
              </CRow>
              <hr class="my-2">
              <CRow v-for="(PropertyData, Index) in LogisticsData.PropertyInfo" :key="Index">
                <CCol col="5">
                  <CInput v-model="PropertyData.Weight" min="0.00" step="0.01" size="sm" :placeholder="$t('Logistics.RelationPropertyWeight')" type="number" />
                </CCol>
                <CCol col="5">
                  <CInput v-model="PropertyData.Fee" size="sm" :placeholder="$t('Logistics.RelationPropertyFee')" type="number" />
                </CCol>
                <CCol col="2" class="text-right">
                  <CButton color="success" size="sm" variant="ghost" v-c-tooltip="$t('Global.Add')" @click="LogisticsData.PropertyInfo.push({})">
                    <CIcon name="cil-plus" class="c-icon-custom-size"/>
                  </CButton>
                  <CButton color="danger" size="sm" variant="ghost" v-c-tooltip="$t('Global.Remove')" @click="LogisticsData.PropertyInfo.splice(Index, 1)">
                    <CIcon name="cil-trash" class="c-icon-custom-size"/>
                  </CButton>
                </CCol>
              </CRow>
            </div>
          </template>
        </CTab>
        <CTab v-show="Features.includes('DeliverySettings')" :title="$t('Logistics.DeliverySettings.Title')">
          <div class="form-group">
            <label class="d-block">{{$t('Logistics.DeliverySettings.Enable')}}</label>
            <CSwitch color="success" :checked.sync="LogisticsData.DeliverySettingsEnable" @update:checked="CheckDeliverySettingsData" />
          </div>
          <template v-if="(LogisticsData.DeliverySettingsEnable === true)">
            <div class="form-group">
              <h6 class="d-flex justify-content-between mb-2">
                <span class="font-weight-bold">{{ $t('Logistics.DeliverySettings.DefaultProcessTime') }}</span>
              </h6>
              <CRow>
                <CCol col="6">
                  <CInput
                    :placeholder="$t('Logistics.DeliverySettings.SelectProcessTime')"
                    type="number"
                    v-model="LogisticsData.DefaultProcessTime"
                  >
                    <template #append-content>
                      {{ $t('Global.TimeUnit.Day') }}
                    </template>
                  </CInput>
                </CCol>
              </CRow>
            </div>
            <div class="form-group">
              <h6 class="d-flex justify-content-between mb-2">
                <span class="font-weight-bold">{{ $t('Logistics.DeliverySettings.DefaultDeliveryTime') }}</span>
              </h6>
              <CRow>
                <CCol col="6">
                  <CInput
                    :placeholder="$t('Logistics.DeliverySettings.SelectDeliveryTime')"
                    type="number"
                    v-model="LogisticsData.DefaultDeliveryTime"
                  >
                    <template #append-content>
                      {{ $t('Global.TimeUnit.Day') }}
                    </template>
                  </CInput>
                </CCol>
              </CRow>
            </div>
            <div class="form-group">
              <h6 class="d-flex justify-content-between mb-2">
                <span class="font-weight-bold"> {{ $t('Logistics.DeliverySettings.ExcludeDeliveryWeekday') }}</span>
              </h6>
              <div>
                <label v-for="(Item, Index) in ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']" :key="Item" :for="Item" class="mr-2">
                  <input type="checkbox" :id="Item" name="DeliveryArrivalWeekdaySettings" :value="Index + 1"
                         v-model="LogisticsData.DeliveryArrivalWeekdaySettings"><span class="ml-2">{{ $t('Global.Weekdays.' + Item) }}</span>
                </label>
              </div>
            </div>
            <div class="form-group">
              <h6 class="d-flex justify-content-between mb-2">
                <span class="font-weight-bold"> {{ $t('Logistics.DeliverySettings.ExcludeDate') }}</span>
                <CButton color="success" size="sm" variant="ghost" v-c-tooltip="$t('Global.Add')"
                         @click="LogisticsData.DeliverySettings.push({})">
                  <CIcon name="cil-plus" class="c-icon-custom-size"/> {{ $t('Global.Add') }}
                </CButton>
              </h6>
              <CRow>
                <CCol col="5">{{ $t('Logistics.DeliverySettings.StartDate') }}</CCol>
                <CCol col="5">{{ $t('Logistics.DeliverySettings.EndDate') }}</CCol>
              </CRow>
              <hr class="my-2">
              <CRow v-for="(Setting, Index) in LogisticsData.DeliverySettings" :key="Index">
                <CCol col="12">
                  <v-date-picker v-model="LogisticsData.DeliverySettings[Index]" is-range>
                    <template v-slot="{ inputValue, inputEvents }">
                      <CRow>
                        <CCol col="5">
                          <CInput :placeholder="$t('Logistics.DeliverySettings.SelectStartDate')" :value="inputValue.start" v-on="inputEvents.start">
                            <template #append-content>
                              <CIcon name="cil-calendar"/>
                            </template>
                          </CInput>
                        </CCol>
                        <CCol col="5">
                          <CInput :placeholder="$t('Logistics.DeliverySettings.SelectEndDate')" :value="inputValue.end" v-on="inputEvents.end">
                            <template #append-content>
                              <CIcon name="cil-calendar"/>
                            </template>
                          </CInput>
                        </CCol>
                        <CCol col="2" class="text-right">
                          <CButton color="danger" size="sm" variant="ghost" v-c-tooltip="$t('Global.Remove')" @click="LogisticsData.DeliverySettings.splice(Index, 1)">
                            <CIcon name="cil-trash" class="c-icon-custom-size"/>
                          </CButton>
                        </CCol>
                      </CRow>
                    </template>
                  </v-date-picker>
                </CCol>
              </CRow>
            </div>
            <div class="form-group mb-0">
              <h6 class="d-flex justify-content-between mb-2">
                <span class="font-weight-bold"> {{ $t('Logistics.DeliverySettings.ExcludeWeekday') }}</span>
              </h6>
              <div>
                <label v-for="(Item, Index) in ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']" :key="Item" :for="Item" class="mr-2">
                  <input type="checkbox" :id="Item" name="DeliveryWeekdaySettings" :value="Index + 1"
                         v-model="LogisticsData.DeliveryWeekdaySettings"><span class="ml-2">{{ $t('Global.Weekdays.' + Item) }}</span>
                </label>
              </div>
            </div>
          </template>
        </CTab>
        <CTab v-show="Features.includes('CreateOrderLogisticsMessages')" :title="$t('Logistics.CreateOrderLogisticsMessages.Title')">
          <div class="form-group">
            <label class="d-block">{{$t('Logistics.CreateOrderLogisticsMessages.Enable')}}</label>
            <CSwitch color="success" :checked.sync="LogisticsData.CreateOrderLogisticsMessagesEnable" />
          </div>
          <template v-if="LogisticsData.CreateOrderLogisticsMessagesEnable">
            <h6 class="d-flex justify-content-between mb-2">
              <span class="font-weight-bold">{{ $t('Logistics.CreateOrderLogisticsMessages.Content') }}</span>
            </h6>
            <Editor api-key="no-api-key" tinymceScriptSrc="https://cdnjs.cloudflare.com/ajax/libs/tinymce/5.10.9/tinymce.min.js" :init="TinyMCE" v-model="LogisticsData.CreateOrderLogisticsMessages" />
          </template>
          <div class="form-group">
            <label class="d-block">{{$t('Logistics.CreateOrderLogisticsMessages.EnablePreAlert')}}</label>
            <CSwitch color="success" :checked.sync="LogisticsData.PreCreateOrderLogisticsMessagesEnable" />
          </div>
          <template v-if="LogisticsData.PreCreateOrderLogisticsMessagesEnable">
            <h6 class="d-flex justify-content-between mb-2">
              <span class="font-weight-bold">{{ $t('Logistics.CreateOrderLogisticsMessages.ContentPreAlert') }}</span>
            </h6>
            <Editor api-key="no-api-key" tinymceScriptSrc="https://cdnjs.cloudflare.com/ajax/libs/tinymce/5.10.9/tinymce.min.js" :init="TinyMCE" v-model="LogisticsData.PreCreateOrderLogisticsMessages" />
          </template>
        </CTab>
      </CTabs>
      <template #footer-wrapper>
        <CElementCover
          v-if="(Submit === true) || (Loading === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#CreateSubmit' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="LogisticsDataModal = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="CreateSubmit" @click="Save()" color="danger">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
  </main>
</template>

<route>
{
"meta": {
"label": "配送方式"
}
}
</route>

<script>
import Editor from '@tinymce/tinymce-vue'
import TinyMCESetting from '@/plugins/tinymce'
import District from '@/assets/json/Districts.json'
export default {
  name: 'LogisticsList',
  layout: 'manage',
  components: {
    Editor
  },
  data () {
    return {
      TinyMCE: TinyMCESetting,
      Features: [],
      List: [],
      ListObject: {},
      LogisticsMethods: [
        { label: this.$t('Logistics.LogisticsMethods.UNIMART'), value: 'UNIMART', location: ['MAIN', 'OUTLYING'], type: 'SUPERSTORE' },
        { label: this.$t('Logistics.LogisticsMethods.FAMI'), value: 'FAMI', location: ['MAIN', 'OUTLYING'], type: 'SUPERSTORE' },
        { label: this.$t('Logistics.LogisticsMethods.HILIFE'), value: 'HILIFE', location: ['MAIN', 'OUTLYING'], type: 'SUPERSTORE' },
        { label: this.$t('Logistics.LogisticsMethods.OKMART'), value: 'OKMART', location: ['MAIN', 'OUTLYING'], type: 'SUPERSTORE' },
        { label: this.$t('Logistics.LogisticsMethods.UNIMARTC2C'), value: 'UNIMARTC2C', location: ['MAIN', 'OUTLYING'], type: 'SUPERSTORE' },
        { label: this.$t('Logistics.LogisticsMethods.FAMIC2C'), value: 'FAMIC2C', location: ['MAIN', 'OUTLYING'], type: 'SUPERSTORE' },
        { label: this.$t('Logistics.LogisticsMethods.HILIFEC2C'), value: 'HILIFEC2C', location: ['MAIN', 'OUTLYING'], type: 'SUPERSTORE' },
        { label: this.$t('Logistics.LogisticsMethods.HILIFEECPACK'), value: 'HILIFEECPACK', location: ['MAIN', 'OUTLYING'], type: 'SUPERSTORE' },
        { label: this.$t('Logistics.LogisticsMethods.OKMARTC2C'), value: 'OKMARTC2C', location: ['MAIN', 'OUTLYING'], type: 'SUPERSTORE' },
        { label: this.$t('Logistics.LogisticsMethods.SHOPEESTORE'), value: 'SHOPEESTORE', location: ['MAIN'], type: 'SUPERSTORE' },
        { label: this.$t('Logistics.LogisticsMethods.HCT'), value: 'HCT', location: ['MAIN', 'OUTLYING'], type: 'EXPRESS' },
        { label: this.$t('Logistics.LogisticsMethods.TCAT'), value: 'TCAT', location: ['MAIN', 'OUTLYING'], type: 'EXPRESS' },
        { label: this.$t('Logistics.LogisticsMethods.ECAN'), value: 'ECAN', location: ['MAIN', 'OUTLYING'], type: 'EXPRESS' },
        { label: this.$t('Logistics.LogisticsMethods.SHOPEEEXPRESS'), value: 'SHOPEEEXPRESS', location: ['MAIN'], type: 'EXPRESS' },
        { label: this.$t('Logistics.LogisticsMethods.JENJANEXPRESS'), value: 'JENJANEXPRESS', location: ['MAIN', 'OUTLYING'], type: 'EXPRESS' },
        { label: this.$t('Logistics.LogisticsMethods.JENJANEXPRESSABROAD'), value: 'JENJANEXPRESSABROAD', location: ['ABROAD'], type: 'EXPRESS' },
        { label: this.$t('Logistics.LogisticsMethods.KERRYTJ'), value: 'KERRYTJ', location: ['MAIN'], type: 'EXPRESS' },
        { label: this.$t('Logistics.LogisticsMethods.SF'), value: 'SF', location: ['MAIN', 'ABROAD'], type: 'EXPRESS' },
        { label: this.$t('Logistics.LogisticsMethods.FEDEX'), value: 'FEDEX', location: ['ABROAD'], type: 'EXPRESS' },
        { label: this.$t('Logistics.LogisticsMethods.SKYNET'), value: 'SKYNET', location: ['ABROAD'], type: 'EXPRESS' },
        { label: this.$t('Logistics.LogisticsMethods.DPEX'), value: 'DPEX', location: ['ABROAD'], type: 'EXPRESS' },
        { label: this.$t('Logistics.LogisticsMethods.DHL'), value: 'DHL', location: ['ABROAD'], type: 'EXPRESS' },
        { label: this.$t('Logistics.LogisticsMethods.OCS'), value: 'OCS', location: ['ABROAD'], type: 'EXPRESS' },
        { label: this.$t('Logistics.LogisticsMethods.UCF'), value: 'UCF', location: ['ABROAD'], type: 'EXPRESS' },
        { label: this.$t('Logistics.LogisticsMethods.TP'), value: 'TP', location: ['MAIN', 'OUTLYING', 'ABROAD'], type: 'EXPRESS' },
        { label: this.$t('Logistics.LogisticsMethods.SELF'), value: 'SELF', location: ['MAIN', 'OUTLYING', 'ABROAD'], type: 'SELF' },
        { label: this.$t('Logistics.LogisticsMethods.IN_USE'), value: 'IN_USE', location: ['MAIN', 'OUTLYING', 'ABROAD'], type: 'SELF' },
        { label: this.$t('Logistics.LogisticsMethods.DELIVERY'), value: 'DELIVERY', location: ['MAIN', 'OUTLYING', 'ABROAD'], type: 'EXPRESS' },
      ],
      SystemAllowMethods: {
        'ECPAY-LOGISTICS': [
          'UNIMART', 'FAMI', 'HILIFE', 'OKMART', 'TCAT', 'UNIMARTC2C', 'FAMIC2C', 'HILIFEC2C', 'OKMARTC2C'
        ],
        'UCFUSHIP-LOGISTICS': [
          'OCS', 'UCF', 'TP', 'DHL'
        ],
        'EZSHIP-LOGISTICS': [
          'UNIMART', 'FAMI', 'HILIFE', 'OKMART'
        ],
        'HCT-LOGISTICS': [
          'HCT'
        ],
        'KERRYTJ-LOGISTICS': [
          'KERRYTJ'
        ],
        'FAMI-LOGISTICS': [
          'FAMI'
        ],
        'JENJAN-LOGISTICS': [
          'JENJANEXPRESS',
          'JENJANEXPRESSABROAD',
          'TP',
          'TCAT',
          'UNIMARTC2C',
          'FAMIC2C',
          'HILIFEC2C',
          'OKMARTC2C',
          // 'SHOPEESTORE',
          // 'SHOPEEEXPRESS',
          // 'HILIFEECPACK',
          'SF',
          // 'SELF',
        ],
        'SF-LOGISTICS': [
          'SF'
        ],
        'DPEX-LOGISTICS': [
          'DPEX', 'SKYNET'
        ],
        'UMEANS-LOGISTICS': [
          'IN_USE',
          'SELF',
          'DELIVERY'
        ]
      },
      LogisticsData: {
        ID: '',
        ServiceID: '',
        Name: '',
        Description: '',
        Image: '',
        Method: '',
        System: '',
        Type: '',
        EnableCashDelivery: true,
        Temperature: 'Common',
        ServiceLocation: 'MAIN',
        ShipmentCountry: 'TW',
        Fee: '0',
        MinimumAmountThreshold: '0',
        IsFreeFee: false,
        FreeFee: '0',
        Status: false,
        ShipperInfo: {
          'Name': '',
          'Company': '',
          'Address': '',
          'ZipCode': '',
          'City': '',
          'Area': '',
          'Country': 'TW',
          'Phone': '',
          'Email': '',
          'TaxID': ''
        },
        StoreInfo: {
          'Name': '',
          'Address': '',
          'City': '',
          'Area': '',
          'Phone': ''
        },
        PropertyInfoEnable: false,
        RelationProperty: 'Weight',
        PropertyInfo: [],
        CheckExcludeAreaEnable: false,
        ExcludeArea: [],
        DeliverySettingsEnable: false,
        DeliverySettings: [],
        DeliveryWeekdaySettings: [],
        DefaultProcessTime: 0,
        DefaultDeliveryTime: 1,
        DeliveryArrivalWeekdaySettings: [],
      },
      District,
      ChooseIndex: null,
      LogisticsDataModal: false,
      Loading: false,
      Submit: false,
      noItemsView: {
        noResults: this.$t('Global.NoResults'),
        noItems: this.$t('Global.NoItems')
      }
    }
  },
  computed: {
    Field () {
      return [
        { key: 'No', label: 'No' },
        { key: 'Name', label: this.$t('Logistics.Name') },
        { key: 'System', label: this.$t('Logistics.System') },
        { key: 'Method', label: this.$t('Logistics.Method') },
        { key: 'Status', label: this.$t('Logistics.Status') },
        { key: 'Action', label: '' }
      ]
    },
    LogisticsSystem () {
      return Object.keys(this.$t('Logistics.LogisticsSystem')).map(system => {
        return {
          value: system,
          label: this.$t('Logistics.LogisticsSystem')[system]
        }
      })
    },
    ServiceLocationOptions () {
      return Object.keys(this.$t('Logistics.ServiceLocationOptions')).filter((option) => {
        const method = this.LogisticsMethods.find((method) => this.LogisticsData.Method === method.value) || {
          location: []
        }
        return method.location.includes(option)
      }).map(option => {
        return {
          value: option,
          label: this.$t('Logistics.ServiceLocationOptions')[option]
        }
      })
    },
    TemperatureOptions () {
      return Object.keys(this.$t('Logistics.TemperatureOptions')).map(option => {
        return {
          value: option,
          label: this.$t('Logistics.TemperatureOptions')[option]
        }
      })
    },
    CountryOptions () {
      return Object.keys(this.$t('Logistics.CountryOptions')).map(option => {
        return {
          value: option,
          label: this.$t('Logistics.CountryOptions')[option]
        }
      })
    },
    RelationPropertyOption () {
      return Object.keys(this.$t('Logistics.RelationPropertyOption')).map(option => {
        return {
          value: option,
          label: this.$t('Logistics.RelationPropertyOption')[option],
          disabled: option === 'Volume'
        }
      })
    },
    LogisticsSystemSetting () {
      return this.LogisticsSystem.map(Logistics => Logistics.value)
    },
    ShowLogisticsMethods () {
      if (typeof this.LogisticsData.System === 'string' && this.LogisticsData.System !== '' && this.LogisticsData.System !== 'CUSTOM') {
        return this.LogisticsMethods.filter((methods) => {
          return this.SystemAllowMethods[this.LogisticsData.System.toUpperCase()].includes(methods.value)
        })
      } else {
        return this.LogisticsMethods
      }
    },
    CurrentMethod () {
      return this.LogisticsMethods.find((method) => method.value === this.LogisticsData.Method) || {}
    },
    City () {
      return this.District.map(Data => Data.name)
    },
    Area () {
      return []
      // const ChooseCityData = this.District.filter(Data => Data.name === this.NowSelectLocation.City) || []
      // if (ChooseCityData.length > 0) {
      //   return ChooseCityData[0].districts.map(Area => Area.name)
      // } else {
      //   return []
      // }
    }
  },
  mounted() {
    this.$Progress.start()
    this.Init().then(() => {
      this.$Progress.finish()
    }).catch((err) => {
      this.$Progress.fail()
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: (err.type ? err.type : 'error')
      })
    })
  },
  methods: {
    Init() {
      const PermissionSession = JSON.parse(localStorage.getItem('Permission'))
      this.Features = PermissionSession.Features.System.Logistics || []
      return Promise.all([
        this.GetList()
      ]).then(() => {
        return true
      }).catch((err) => {
        throw err
      })
    },
    GetList() {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/system/logistics/list',
        method: 'get'
      }).then(({data}) => {
        this.Loading = false
        this.List = Object.keys(data).map((id) => {
          return {
            ...data[id],
            ID: id,
            System: data[id].System.toUpperCase()
          }
        })
        this.ListObject = data
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    Save () {
      let Action
      const ChooseMethods = this.LogisticsMethods.find((method) => method.value === this.LogisticsData.Method) || {}
      this.Loading = true
      this.LogisticsData.Type = ChooseMethods.type
      if (Array.isArray(this.LogisticsData.DeliverySettings)) {
        this.LogisticsData.DeliverySettings = this.LogisticsData.DeliverySettings.filter(item => item.start && item.end).map(el => ({start: new Date(el.start).getTime(), end: new Date(el.end).getTime()}))
      }
      if (!this.ChooseIndex) {
        Action = this.Add()
      } else {
        Action = this.Edit(this.ChooseIndex)
      }
      return Action.then(() => {
        this.LogisticsDataModal = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.System/' + (this.ChooseIndex ? 'Update' : 'Add') + 'ShipmentSuccess'),
          type: 'success'
        })
        this.GetList()
      }).catch((error) => {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/ErrorMessage') + error.msg,
          type: (error.type ? error.type : 'error')
        })
      })
    },
    Add () {
      return this.$store.dispatch('InnerRequest', {
        url: '/system/logistics/add',
        method: 'post',
        data: {
          LogisticsData: {
            ...this.LogisticsData,
            Name: (this.LogisticsData.Name === '' ? this.$t('Logistics.LogisticsMethods.' + this.LogisticsData.Method) : this.LogisticsData.Name),
            System: this.LogisticsData.System
          }
        }
      }).then(() => {
        this.Loading = false
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    Edit (ID) {
      return this.$store.dispatch('InnerRequest', {
        url: '/system/logistics/set',
        method: 'post',
        data: {
          ID,
          LogisticsData: this.LogisticsData
        }
      }).then(() => {
        this.Loading = false
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    Delete(ID) {
      return this.$swal({
        icon: 'info',
        title: this.$t('Message.AskDelete'),
        text: '此操作將不可回覆',
        showCancelButton: true,
        confirmButtonColor: '#2eb85c',
        confirmButtonText: this.$t('Global.Confirm'),
        cancelButtonText: this.$t('Global.Cancel'),
      }).then((result) => {
        if (result.isConfirmed) {
          this.Submit = true
          return this.$store.dispatch('InnerRequest', {
            url: '/system/logistics/delete',
            method: 'post',
            data: {
              ID
            }
          }).then(() => {
            this.Init()
            this.$notify({
              group: 'notify',
              title: this.$t('Message.Success'),
              text: this.$t('Message.Global/DeleteSuccess'),
              type: 'success'
            })
          }).catch((err) => {
            this.$notify({
              group: 'notify',
              title: this.$t('Message.Error'),
              text: this.$t('Message.Global/DeleteFail') + err.msg,
              type: 'error'
            })
          })
        }
      })
    },
    CheckPropertyInfoEnable () {
      if (this.LogisticsData.ServiceLocation !== 'ABROAD') {
        this.LogisticsData.PropertyInfoEnable = false
      }
    },
    CheckExcludeAreaEnable () {
      if (this.CurrentMethod.type === 'SUPERSTORE' || this.LogisticsData.ServiceLocation === 'ABROAD') {
        this.LogisticsData.CheckExcludeAreaEnable = false
      }
    },
    CheckDeliverySettingsData (value) {
      if (value === true && typeof this.LogisticsData.DeliverySettings === 'undefined') {
        this.$set(this.LogisticsData, 'DeliverySettings', [])
      }
      if (value === true && typeof this.LogisticsData.DefaultProcessTime === 'undefined') {
        this.$set(this.LogisticsData, 'DefaultProcessTime', 0)
      }
    },
    OpenModal(ID = null) {
      this.Submit = false
      this.ChooseIndex = ID
      this.LogisticsDataModal = true
      if (ID !== null) {
        this.LogisticsData = {
          StoreInfo: {
            'Name': '',
            'Address': '',
            'City': '',
            'Area': '',
            'Phone': ''
          },
          ...this.ListObject[ID]
        }
        this.SetDeliverySettings()
      } else {
        this.LogisticsData = {
          ID: '',
          ServiceID: '',
          Name: '',
          Description: '',
          Image: '',
          Method: '',
          System: '',
          Type: '',
          EnableCashDelivery: true,
          Temperature: 'Common',
          ServiceLocation: 'MAIN',
          Fee: 0,
          MinimumAmountThreshold: 0,
          IsFreeFee: false,
          FreeFee: 0,
          Status: false,
          ShipperInfo: {
            'Name': '',
            'Company': '',
            'Address': '',
            'ZipCode': '',
            'City': '',
            'Area': '',
            'Country': 'TW',
            'Phone': '',
            'Email': '',
            'TaxID': ''
          },
          StoreInfo: {
            'Name': '',
            'Address': '',
            'City': '',
            'Area': '',
            'Phone': ''
          },
          PropertyInfoEnable: false,
          RelationProperty: 'Weight',
          PropertyInfo: [
            {
              Weight: '',
              Volume: '',
              VolumeProperty: '',
              Fee: '',
            }
          ],
          DeliverySettingsEnable: false,
          DeliverySettings: [
            {
              start: '',
              end: ''
            }
          ],
          DeliveryWeekdaySettings: [],
          DefaultProcessTime: 0,
          DefaultDeliveryTime: 1,
          DeliveryArrivalWeekdaySettings: [],
        }
      }
    },
    SetDeliverySettings () {
      if (!this.LogisticsData.DeliveryWeekdaySettings) {
        this.$set(this.LogisticsData, 'DeliveryWeekdaySettings', [])
      }
      if (!this.LogisticsData.DeliveryArrivalWeekdaySettings) {
        this.$set(this.LogisticsData, 'DeliveryArrivalWeekdaySettings', [])
      }
      if (!this.LogisticsData.DeliverySettings) {
        this.$set(this.LogisticsData, 'DeliverySettings', [])
      }
      return Promise.resolve()
    },
    AddExcludeArea () {
      if (!this.LogisticsData.ExcludeArea) {
        this.$set(this.LogisticsData, 'ExcludeArea', [])
      }
      this.LogisticsData.ExcludeArea.push({
        City: '',
        Area: '',
        ZipCode: ''
      })
    },
    DataArea (index) {
      const EnableArea = ['全區']
      const ChooseCityData = this.District.find(Data => Data.name === this.LogisticsData.ExcludeArea[index].City)
      if (ChooseCityData) {
        EnableArea.push(...ChooseCityData.districts.map(Area => Area.name) )
      }
      return EnableArea
    },
    Duplicate(ID) {
      this.LogisticsData = {
        StoreInfo: {
          'Name': '',
          'Address': '',
          'City': '',
          'Area': '',
          'Phone': ''
        },
        ...this.ListObject[ID],
      }
      this.LogisticsData.Name += '_copy'
      this.Add().then(() => {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.System/CopyShipmentSuccess'),
          type: 'success'
        })
        this.GetList()
      }).catch((error) => {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/ErrorMessage') + error.msg,
          type: (error.type ? error.type : 'error')
        })
      })
    }
  }
}
</script>

<style>
#LogisticsList thead th:first-child {
  text-align: center;
}
#LogisticsList tbody td:first-child {
  vertical-align: middle;
}
</style>
